import {
  IntegrationApplication,
  removeApplications,
} from '@wix/members-area-integration-kit';
import EditorWrapper from './EditorWrapper';
import {
  EXPERIMENTS_SCOPE,
  GROUP_PAGE_ID,
  GROUP_PAGES,
  GROUPS_DASHBOARD_URL,
  GROUPS_MEMBERS_AREA_PAGE_ID,
  GROUPS_PAGE_ID,
} from '../app-configs';
import { GroupsAppSpec } from './types/AppSpec';
import { Translations } from './Translations';
import { getGroupsManifest } from './manifest';
import AppManifest from './types/manifest';
import { pageSettingsUrls } from '../pages-settings/pageSettingsUrls';
import { PageData, TPARef } from './types/common';
import { AppDescriptorAction } from './appDescriptor';
import { GROUPS_WIDGET_ID } from '@wix/social-groups-common/dist/src/config/constants';
import { getExperimentsByScope } from '../experiments/getExperimentsByScope';
import { InitialAppData } from './types/editor-app';
import Experiments from '@wix/wix-experiments';

class GroupPlatform {
  private readonly editor: EditorWrapper;
  private translation: Translations;
  private experiments: Experiments;

  constructor(
    editorSDK,
    public readonly appDefId: string,
    private readonly config: InitialAppData,
  ) {
    this.editor = new EditorWrapper(editorSDK, appDefId);
  }

  async install() {
    await this.setupGroupsPages();
  }

  private async setupGroupsPages() {
    const [tpaData, allSitePages] = await Promise.all([
      this.editor.getDataByAppDefId(this.appDefId),
      this.editor.getAllPages(),
    ]);
    const { applicationId } = tpaData;

    const groupPages = this.getGroupsTPARefs(allSitePages, applicationId);
    await this.editor.addToManagedPages(groupPages);
    await this.editor.save();
  }

  async setGroupPageState(applicationId: string) {
    try {
      const groupPage = await this.findGroupPage(applicationId);
      const { id } = groupPage;
      await this.editor.setPageState({ groupPage: [{ id }] });
    } catch (e) {
      console.error('Set group page state: FAIL');
    }
  }

  private async findGroupPage(applicationId: string) {
    const allSitePages = await this.editor.getAllPages();
    const groupPage: PageData = allSitePages.find((page) => {
      return (
        page.managingAppDefId === applicationId &&
        page.tpaPageId === GROUP_PAGE_ID
      );
    });
    return groupPage;
  }

  async navigateToGroupPage() {
    try {
      const groupPage = await this.findGroupPage(this.appDefId);
      const { id } = groupPage;
      await this.editor.navigate(id);
    } catch (e) {
      console.log('[GroupPlatform.navigateToGroupPage] Error');
    }
  }

  // For sites where groups members area was installed
  // TODO: 🚨 remove later see https://jira.wixpress.com/browse/GROUP-673
  async deleteGroupsMemberArea() {
    try {
      const allSitePages = await this.editor.getAllPages();
      const groupPage: PageData = allSitePages.find((page) => {
        return page.tpaPageId === GROUPS_MEMBERS_AREA_PAGE_ID;
      });
      if (groupPage) {
        await removeApplications([
          { pageId: GROUPS_MEMBERS_AREA_PAGE_ID } as IntegrationApplication,
        ]);
      }
    } catch (e) {
      console.error('Remove group member area page: FAIL');
    }
  }

  private getGroupsTPARefs(allSitePages, applicationId) {
    const groupPages: TPARef[] = allSitePages
      .filter(
        (page) =>
          page.tpaApplicationId === applicationId &&
          GROUP_PAGES.includes(page.tpaPageId),
      )
      .map(({ id, tpaPageId }) => {
        return { title: tpaPageId, pageRef: { id } };
      });
    return groupPages;
  }

  async getManifest(): Promise<AppManifest> {
    const baseUrl = await this.getStaticsEditorBaseUrl();
    const translation = await this.getTranslation(baseUrl);
    const withAppDescriptor = await this.appDescriptorEnabled();
    return getGroupsManifest(
      translation,
      pageSettingsUrls(baseUrl),
      withAppDescriptor,
    );
  }

  private async appDescriptorEnabled() {
    try {
      const experiments = await this.getExperiments();
      const appDescriptorSpec = 'specs.groups.appDescriptor';
      await experiments.conduct(appDescriptorSpec, 'false');
      return experiments.enabled(appDescriptorSpec);
    } catch (e) {}
    return false;
  }

  private async getTranslation(baseUrl: string) {
    if (!this.translation) {
      const locale = await this.editor.getLocale();
      this.translation = new Translations();
      await this.translation.editorInit(locale, baseUrl);
    }
    return this.translation;
  }

  private async getStaticsEditorBaseUrl() {
    const {
      appFields: {
        platform: { baseUrls },
      },
    } = await this.editor.getAppData<GroupsAppSpec>();
    return baseUrls.staticsEditorBaseUrl;
  }

  addPage() {
    return this.editor.addPage();
  }

  private getGroupsTPARef(allSitePages, applicationId) {
    const groupsPage: PageData = allSitePages.find(
      (page) =>
        page.tpaApplicationId === applicationId &&
        page.tpaPageId === GROUPS_PAGE_ID,
    );
    return { title: GROUPS_PAGE_ID, pageRef: { id: groupsPage.id } };
  }

  async deleteApp() {
    const [tpaData, allSitePages] = await Promise.all([
      this.editor.getDataByAppDefId(this.appDefId),
      this.editor.getAllPages(),
    ]);
    const { applicationId } = tpaData;
    const groupsPage = this.getGroupsTPARef(allSitePages, applicationId);
    if (groupsPage) {
      return this.editor.deletePage(groupsPage);
    }
  }

  handleInstallError() {}

  performAction(actionId: AppDescriptorAction) {
    switch (actionId) {
      case AppDescriptorAction.MAIN_ACTION:
        return this.openDashboard();
      case AppDescriptorAction.CREATE_GROUP:
        return this.openDashboard('/templates');
      case AppDescriptorAction.ADD_WIDGET:
        return this.openAddWidget();
      case AppDescriptorAction.SETUP:
        return this.openDashboard('/settings');
      default:
        return;
    }
  }
  private openDashboard(section: string = '') {
    this.editor.openDashboard(GROUPS_DASHBOARD_URL + section);
  }

  private async openAddWidget() {
    try {
      const pageLink = await this.editor.openAppPage(GROUPS_PAGE_ID);
      if (pageLink) {
        const title = await this.getAddonsTitle();
        const componentRef = await this.editor.getComponentRef(
          GROUPS_WIDGET_ID,
        );
        const url = await this.getAddonsUrl(componentRef.id);
        return this.editor.openModal(url, title, componentRef).then(() => {
          console.log('GroupPlatform.openAddWidget');
        });
      }
    } catch (e) {
      console.log('[GroupPlatform.openAddWidget] Error');
    }
  }

  private async getAddonsTitle() {
    const baseUrl = await this.getStaticsEditorBaseUrl();
    const translation = await this.getTranslation(baseUrl);
    return translation.t('groups-web.settings.addons');
  }

  private async getAddonsUrl(origCompId) {
    const instance =
      this.config.instance || (await this.editor.getAppInstance());
    return `//groups.wixapps.net/social-groups/api/render/social-groups-app/widgetList/?instance=${instance}&origCompId=${origCompId}&compId=kcxcc7pk`;
  }

  async getExperiments() {
    if (!this.experiments) {
      const entityId = this.config && this.config.metaSiteId;
      this.experiments = await getExperimentsByScope(
        EXPERIMENTS_SCOPE,
        entityId,
      );
    }
    return this.experiments;
  }

  async landingPageEnabled() {
    try {
      const experiments = await this.getExperiments();
      const spec = 'specs.groups.changeLanding';
      await experiments.conduct(spec, 'false');
      return experiments.enabled(spec);
    } catch (e) {
      console.log('[GroupPlatform.landingPageEnabled] Error');
    }
    return false;
  }

  async changeLandingPage() {
    try {
      // TODO: when the experiment is done delete this code (maybe change default page in app json)
      if (await this.landingPageEnabled()) {
        const eventType = 'focusedPageChanged';
        const handler = async (event) => {
          await Promise.all([
            this.navigateToGroupPage(),
            this.editor.removeEventListener(eventType, handler),
          ]);
        };
        await this.editor.addEventListener(eventType, handler);
      }
    } catch (e) {
      console.log('[GroupPlatform.changeLandingPage] Error');
    }
  }
}

export default GroupPlatform;
