import Experiments from '@wix/wix-experiments';

export async function getExperimentsByScope(
  scope: string,
  entityId: string,
  baseUrl?: string,
) {
  const experiments = new Experiments({
    scope,
    baseUrl,
    requestContext: { overrideCriteria: { entityId } },
    useNewApi: true,
  });
  return experiments;
}

export async function conductAllByScope(
  scope: string,
  metaSiteId: string,
  baseUrl?: string,
) {
  const experiments = await getExperimentsByScope(scope, metaSiteId, baseUrl);
  experiments.conduct('specs.NewMembersApi', 'false').catch();
  await experiments.ready();
  return experiments.all();
}
